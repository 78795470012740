@font-face {
font-family: '__geistSans_1e4310';
src: url(/_next/static/media/4473ecc91f70f139-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__geistSans_Fallback_1e4310';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_1e4310 {font-family: '__geistSans_1e4310', '__geistSans_Fallback_1e4310'
}.__variable_1e4310 {--font-geist-sans: '__geistSans_1e4310', '__geistSans_Fallback_1e4310'
}

@font-face {
font-family: '__geistMono_c3aa02';
src: url(/_next/static/media/463dafcda517f24f-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__geistMono_Fallback_c3aa02';src: local("Arial");ascent-override: 69.97%;descent-override: 16.73%;line-gap-override: 7.61%;size-adjust: 131.49%
}.__className_c3aa02 {font-family: '__geistMono_c3aa02', '__geistMono_Fallback_c3aa02'
}.__variable_c3aa02 {--font-geist-mono: '__geistMono_c3aa02', '__geistMono_Fallback_c3aa02'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

@font-face {
font-family: '__objectivityBlack_1c824a';
src: url(/_next/static/media/eeb13cbcc6a1e9e1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityBlack_Fallback_1c824a';src: local("Arial");ascent-override: 60.32%;descent-override: 18.50%;line-gap-override: 4.66%;size-adjust: 124.34%
}.__className_1c824a {font-family: '__objectivityBlack_1c824a', '__objectivityBlack_Fallback_1c824a'
}.__variable_1c824a {--font-objectivity-black: '__objectivityBlack_1c824a', '__objectivityBlack_Fallback_1c824a'
}

@font-face {
font-family: '__objectivityBlackSlanted_1278b9';
src: url(/_next/static/media/6d04da7a6a6af5f6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityBlackSlanted_Fallback_1278b9';src: local("Arial");ascent-override: 60.32%;descent-override: 18.50%;line-gap-override: 4.66%;size-adjust: 124.34%
}.__className_1278b9 {font-family: '__objectivityBlackSlanted_1278b9', '__objectivityBlackSlanted_Fallback_1278b9'
}.__variable_1278b9 {--font-objectivity-black-slanted: '__objectivityBlackSlanted_1278b9', '__objectivityBlackSlanted_Fallback_1278b9'
}

@font-face {
font-family: '__objectivityBold_142b0c';
src: url(/_next/static/media/0a4dca35f997eabb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityBold_Fallback_142b0c';src: local("Arial");ascent-override: 61.97%;descent-override: 19.21%;line-gap-override: 4.84%;size-adjust: 119.74%
}.__className_142b0c {font-family: '__objectivityBold_142b0c', '__objectivityBold_Fallback_142b0c'
}.__variable_142b0c {--font-objectivity-bold: '__objectivityBold_142b0c', '__objectivityBold_Fallback_142b0c'
}

@font-face {
font-family: '__objectivityBoldSlanted_f7b2d8';
src: url(/_next/static/media/999a75438177e390-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityBoldSlanted_Fallback_f7b2d8';src: local("Arial");ascent-override: 61.97%;descent-override: 19.21%;line-gap-override: 4.84%;size-adjust: 119.74%
}.__className_f7b2d8 {font-family: '__objectivityBoldSlanted_f7b2d8', '__objectivityBoldSlanted_Fallback_f7b2d8'
}.__variable_f7b2d8 {--font-objectivity-bold-slanted: '__objectivityBoldSlanted_f7b2d8', '__objectivityBoldSlanted_Fallback_f7b2d8'
}

@font-face {
font-family: '__objectivityExtraBold_b10c0c';
src: url(/_next/static/media/7e6dec22336f3ff6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityExtraBold_Fallback_b10c0c';src: local("Arial");ascent-override: 61.07%;descent-override: 18.83%;line-gap-override: 4.75%;size-adjust: 122.15%
}.__className_b10c0c {font-family: '__objectivityExtraBold_b10c0c', '__objectivityExtraBold_Fallback_b10c0c'
}.__variable_b10c0c {--font-objectivity-extra-bold: '__objectivityExtraBold_b10c0c', '__objectivityExtraBold_Fallback_b10c0c'
}

@font-face {
font-family: '__objectivityExtraBoldSlanted_0612bf';
src: url(/_next/static/media/6f21931d5c0ff47b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityExtraBoldSlanted_Fallback_0612bf';src: local("Arial");ascent-override: 61.07%;descent-override: 18.83%;line-gap-override: 4.75%;size-adjust: 122.15%
}.__className_0612bf {font-family: '__objectivityExtraBoldSlanted_0612bf', '__objectivityExtraBoldSlanted_Fallback_0612bf'
}.__variable_0612bf {--font-objectivity-extra-bold-slanted: '__objectivityExtraBoldSlanted_0612bf', '__objectivityExtraBoldSlanted_Fallback_0612bf'
}

@font-face {
font-family: '__objectivityLight_699307';
src: url(/_next/static/media/e2eba2f5fccec805-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityLight_Fallback_699307';src: local("Arial");ascent-override: 64.32%;descent-override: 20.21%;line-gap-override: 5.10%;size-adjust: 113.81%
}.__className_699307 {font-family: '__objectivityLight_699307', '__objectivityLight_Fallback_699307'
}.__variable_699307 {--font-objectivity-light: '__objectivityLight_699307', '__objectivityLight_Fallback_699307'
}

@font-face {
font-family: '__objectivityLightSlanted_444d39';
src: url(/_next/static/media/3aa607b9b4cf002d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityLightSlanted_Fallback_444d39';src: local("Arial");ascent-override: 64.32%;descent-override: 20.21%;line-gap-override: 5.10%;size-adjust: 113.81%
}.__className_444d39 {font-family: '__objectivityLightSlanted_444d39', '__objectivityLightSlanted_Fallback_444d39'
}.__variable_444d39 {--font-objectivity-light-slanted: '__objectivityLightSlanted_444d39', '__objectivityLightSlanted_Fallback_444d39'
}

